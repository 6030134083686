<template>
  <div class="setting-container">
    <div class="ceter_back"   @click="gocenter">
      <span class="iconfont iconico_back"></span>
      <span class="back">帮助中心</span>
    </div>
    <div class="main_cont">
      <div class="main_left">
        <div class="side_user flexAC">
          <div><img src="../../assets/img/user_logo.png"/></div>
          <div class="su_right">
            <p class="userName">{{ username }}</p>
            <p class="roleName">{{ rolename }}</p>
          </div>
        </div>
        <div class="su_line"></div>
        <div class="side_cont">
          <div class="er_item" @click="tabClick(1)" :class="{'selected': tabId == 1}">施工说明</div>
          <div class="er_item" @click="tabClick(2)" :class="{'selected': tabId == 2}">平台操作说明</div>
        </div>
      </div>
      <div class="main_right">
        <div v-if="tabId == 1">
          <div class="main_one">
            <div class="udi_k"></div>
            <p class="udi_t">施工说明</p>
          </div>
          <div class="main_two">
            <pdf  :src="src"></pdf>
          </div>
          <!-- <div class="main_two">
            <div class="flexCB">
              <p class="ud_t">设备配置说明</p>
              <p class="ud_d">2020.12.12</p>
            </div>
            <p class="ud_f">关于平台对接TCP/UDP协议的设备配置说明</p>
            <div class="ud_line"></div>
          </div> -->
        </div>
        <div v-if="tabId == 2">正在加班开发中......</div>

        <!-- <el-pagination
        @current-change="PageChange"
        :current-page="page"
        :page-sizes="[10]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        class="settingPage centerPage" >
        </el-pagination> -->
      </div>
    </div>
  </div>
</template>

<script>
import { imPlementAtion } from '@/http/api/settingSystem.js'
import pdf from 'vue-pdf'

export default {
  components: {pdf},
  data () {
    return {
      username: '',
      rolename: '',
      tabId: 1,
      src: ''
    }
  },
  created () {
    this.username = JSON.parse(window.localStorage.getItem('loginInfo')).username
    this.rolename = JSON.parse(window.localStorage.getItem('loginInfo')).rolename
    this.init()
  },
  methods: {
    init () {
      imPlementAtion({}).then(res => {
        if (res.status === '1') {
          this.src = res.data.file
        } else {
          this.src = ''
          this.$message.error(res.message)
        }
      })
    },
    
    gocenter () {
      this.$emit('gocenter')
    },
    tabClick (type) {
      this.tabId = type
    },
    PageChange (page) {
      this.page = page
    }
  }
}
</script>

<style scoped>
.setting-container{
  width: 100%;
  height: 100%;
  background: #F0F3F8;
}
.ceter_back {
  width: 100%;
  height: 60px;
  background: #FFFFFF;
  opacity: 1;
  padding: 0px 40px;
  box-sizing: border-box;
}
.ceter_back .iconico_back {
  font-size: 24px;
  cursor: pointer;
  line-height: 30px;
  display: inline-block;
  vertical-align:middle;
}
.back {
  font-size: 19px;
  font-family: 'PingFang SC';
  font-weight: 400;
  color: #333333;
  opacity: 1;
  cursor: pointer;
  text-align: center;
  line-height: 60px;
  display: inline-block;
  vertical-align:middle;
}
.main_cont {
  width: 100%;
  height: 100%;
  display: flex;
  text-align: center;
  margin-top: 30px;
  justify-content: center;
}
.main_left {
  width: 240px;
  height: 263px;
  background: #FFFFFF;
  opacity: 1;
  border-radius: 4px;
  margin-right: 20px;
}
.main_right {
  width: 840px;
  height: calc(100% - 120px);
  background: #FFFFFF;
  opacity: 1;
  border-radius: 4px;
  /* margin-top: 30px; */
  overflow-y: scroll;
  scrollbar-width: none;
}
.main_right::-webkit-scrollbar {
  width: 0px;
}
.side_user, .side_cont{
  padding: 30px 20px 20px;
  box-sizing: border-box;
}
.su_right{
  margin-left: 16px;
  text-align:left;
}
.userName{
  font-size: 18px;
  color: #333333;
  font-weight: 400;
}
.roleName {
  margin-top: 5px;
  font-size: 14px;
  color: #6F7E90;
  font-weight: 400;
}
.su_line {
  width: 200px;
  height: 1px;
  background: #E1E4E9;
  opacity: 1;
  margin-left: 20px;
}
.selected {
  background: rgba(52, 118, 243, 0.2);
  border-radius: 4px;
  color: #3476F3;
}
.er_item {
  padding: 0 30px;
  height: 60px;
  line-height: 60px;
  /* color: #333333; */
  cursor: pointer;
  font-size: 14px;
}
.main_one {
  padding: 20px 30px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  border-bottom:1px solid #E1E4E9;
}
.udi_k {
  width: 4px;
  height: 17px;
  background: #196DF7;
  opacity: 1;
  border-radius: 1px;
}
.main_two {
  padding: 20px 30px;
  box-sizing: border-box;
  /* width: 100%;
  height: calc(100% - 90px); */
}
.ud_t {
  font-size: 16px;
  font-family: 'PingFang SC';
  font-weight: 800;
  color: #333333;
  opacity: 1;
}
.ud_d {
  font-size: 14px;
  font-family: 'PingFang SC';
  font-weight: 800;
  color: #6F7E90;
  opacity: 1;
}
.ud_f {
  text-align:left;
  font-size: 14px;
  font-family: 'PingFang SC';
  font-weight: 800;
  color: #6F7E90;
  opacity: 1;
  margin-top: 10px;
}
.ud_line {
  margin-top: 20px;
  width: 100%;
  height: 1px;
  background: #E1E4E9;
  opacity: 1;
}
.centerPage {
  width: 97%;
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
}
</style>
