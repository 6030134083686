<template>
  <div class="sidebar">
    <el-menu
      class="sidebar-el-menu"
      background-color="#196DF7"
      :default-active="onRoutes"
      text-color="#88ACFC"
      active-text-color="#ffffff"
      unique-opened
      router>
      <div v-for="(item,eq) in menus" :key="item.name">
        <div v-if="item.level === false">
<!--          <template v-if="item.subs">-->
<!--            <el-submenu :index="item.name" :key="item.name">-->
<!--              <template slot="title">-->
<!--                <div class="flexAC syTitle">-->
<!--                  <span class="iconfont menuIco" :class="item.ico"></span>-->
<!--                  <span slot="title">{{ item.title }}</span>-->
<!--                </div>-->
<!--              </template>-->
<!--              <div v-for="(subItem,i) in item.subs" :key="subItem.name">-->
<!--                <el-menu-item style="padding-left: 65px;" @click="selectMenu(eq,i)"-->
<!--                  :index="subItem.name"-->
<!--                  :key="subItem.name"-->
<!--                >{{ subItem.title }}</el-menu-item>-->
<!--              </div>-->
<!--            </el-submenu>-->
<!--          </template>-->
          <!-- 一级菜单 -->
          <template v-if="item.power">
            <el-menu-item :index="item.name" :key="item.name" @click="selectMenu(eq)">
              <div class="flexAC">
                <span class="iconfont menuIco" :class="item.ico"></span>
                <span slot="title">{{ item.title }}</span>
              </div>
            </el-menu-item>
          </template>
        </div>
        <div v-if="item.level === true">
          <template v-if="item.power">
            <el-submenu :index="item.name" :key="item.name">
              <template slot="title" v-if="item.power">
                <div class="flexAC syTitle">
                  <span class="iconfont menuIco" :class="item.ico"></span>
                  <span slot="title">{{ item.title }}</span>
                </div>
              </template>
              <div v-for="(subItem,i) in item.children" :key="subItem.name">
                <template v-if="subItem.power">
                  <el-menu-item style="padding-left: 65px;" @click="selectMenu(eq,i)"
                                :index="subItem.name"
                                :key="subItem.name"
                  >{{ subItem.title }}</el-menu-item>
                </template>
              </div>
            </el-submenu>
          </template>
        </div>
      </div>
    </el-menu>
  </div>
</template>

<script>
var Base64 = require('js-base64').Base64
export default {
  name: 'Menu',
  data () {
    return {
      menus: '',
      menuLabel: ''
    }
  },
  computed: {
    onRoutes () {
      this.$emit('path', this.$route.name)
      return this.$route.name
    }
  },
  created () {
    var roleper = JSON.parse(window.localStorage.getItem('loginInfo')).roleper
    this.menus = JSON.parse(Base64.decode(roleper))

    // this.menus = [
    //   {
    //     name: 'unit',
    //     title: '单位管理',
    //     ico: 'iconunit',
    //     level: false,
    //     power: true,
    //     children: [
    //       {
    //         title: '新增',
    //         name: 'add',
    //         power: true,
    //         children: []
    //       },
    //       {
    //         title: '删除',
    //         name: 'delete',
    //         power: true,
    //         children: []
    //       },
    //       {
    //         title: '编辑',
    //         name: 'edit',
    //         power: true,
    //         children: []
    //       },
    //       {
    //         title: '导出',
    //         name: 'export',
    //         power: true,
    //         children: []
    //       }
    //     ]
    //   },
    //   {
    //     name: 'maintenance',
    //     title: '维保管理',
    //     ico: 'iconance',
    //     level: false,
    //     power: true,
    //     children: [
    //       {
    //         title: '新增',
    //         name: 'add',
    //         power: true,
    //         children: []
    //       },
    //       {
    //         title: '删除',
    //         name: 'delete',
    //         power: true,
    //         children: []
    //       },
    //       {
    //         title: '编辑',
    //         name: 'edit',
    //         power: true,
    //         children: []
    //       },
    //       {
    //         title: '人员添加',
    //         name: 'userAdd',
    //         power: true,
    //         children: []
    //       }
    //     ]
    //   },
    //   {
    //     name: 'equip',
    //     title: '设备管理',
    //     ico: 'iconequip',
    //     level: true,
    //     power: true,
    //     children: [
    //       {
    //         name: 'equipType',
    //         title: '设备类型',
    //         power: true,
    //         children: [
    //           {
    //             title: '新增',
    //             name: 'add',
    //             power: true,
    //             children: []
    //           },
    //           {
    //             title: '删除',
    //             name: 'delete',
    //             power: true,
    //             children: []
    //           },
    //           {
    //             title: '编辑',
    //             name: 'edit',
    //             power: true,
    //             children: []
    //           }
    //         ]
    //       },
    //       {
    //         name: 'equip',
    //         title: '设备列表',
    //         power: true,
    //         children: [
    //           {
    //             title: '新增',
    //             name: 'add',
    //             power: true,
    //             children: []
    //           },
    //           {
    //             title: '删除',
    //             name: 'delete',
    //             power: true,
    //             children: []
    //           },
    //           {
    //             title: '编辑',
    //             name: 'edit',
    //             power: true,
    //             children: []
    //           },
    //           {
    //             title: '导出',
    //             name: 'export',
    //             power: true,
    //             children: []
    //           }
    //         ]
    //       },
    //       {
    //         name: 'equipImport',
    //         title: '设备导入',
    //         power: true,
    //         children: []
    //       }
    //     ]
    //   },
    //   {
    //     name: 'user',
    //     title: '用户管理',
    //     ico: 'iconuser',
    //     level: true,
    //     power: true,
    //     children: [
    //       {
    //         name: 'userSetting',
    //         title: '用户列表',
    //         power: true,
    //         children: [
    //           {
    //             title: '新增',
    //             name: 'add',
    //             power: true,
    //             children: []
    //           },
    //           {
    //             title: '删除',
    //             name: 'delete',
    //             power: true,
    //             children: []
    //           },
    //           {
    //             title: '编辑',
    //             name: 'edit',
    //             power: true,
    //             children: []
    //           },
    //           {
    //             title: '重置密码',
    //             name: 'pwd',
    //             power: true,
    //             children: []
    //           }
    //         ]
    //       },
    //       {
    //         name: 'systemRole',
    //         title: '系统角色',
    //         power: true,
    //         children: [
    //           {
    //             title: '新增',
    //             name: 'add',
    //             power: true,
    //             children: []
    //           },
    //           {
    //             title: '删除',
    //             name: 'delete',
    //             power: true,
    //             children: []
    //           },
    //           {
    //             title: '权限编辑',
    //             name: 'edit',
    //             power: true,
    //             children: []
    //           }
    //         ]
    //       }
    //     ]
    //   },
    //   {
    //     name: 'build',
    //     title: '建筑管理',
    //     ico: 'iconbuild',
    //     level: false,
    //     power: true,
    //     children: [
    //       {
    //         title: '新增',
    //         name: 'add',
    //         power: true,
    //         children: []
    //       },
    //       {
    //         title: '删除',
    //         name: 'delete',
    //         power: true,
    //         children: []
    //       },
    //       {
    //         title: '编辑',
    //         name: 'edit',
    //         power: true,
    //         children: []
    //       }
    //     ]
    //   },
    //   {
    //     name: 'fire',
    //     title: '微消防管理',
    //     ico: 'iconfire',
    //     level: false,
    //     power: true,
    //     children: [
    //       {
    //         title: '新增',
    //         name: 'add',
    //         power: true,
    //         children: []
    //       },
    //       {
    //         title: '删除',
    //         name: 'delete',
    //         power: true,
    //         children: []
    //       },
    //       {
    //         title: '编辑',
    //         name: 'edit',
    //         power: true,
    //         children: []
    //       }
    //     ]
    //   },
    //   {
    //     name: 'plan',
    //     title: '预案管理',
    //     ico: 'iconplan',
    //     level: false,
    //     power: true,
    //     children: [
    //       {
    //         title: '新增',
    //         name: 'add',
    //         power: true,
    //         children: []
    //       },
    //       {
    //         title: '删除',
    //         name: 'delete',
    //         power: true,
    //         children: []
    //       },
    //       {
    //         title: '编辑',
    //         name: 'edit',
    //         power: true,
    //         children: []
    //       }
    //     ]
    //   },
    //   {
    //     name: 'onsite',
    //     title: '巡检管理',
    //     ico: 'icononsite',
    //     level: true,
    //     power: true,
    //     children: [
    //       {
    //         name: 'onsiteLabel',
    //         title: '巡检标签',
    //         power: true,
    //         children: [
    //           {
    //             title: '新增',
    //             name: 'add',
    //             power: true,
    //             children: []
    //           },
    //           {
    //             title: '删除',
    //             name: 'delete',
    //             power: true,
    //             children: []
    //           },
    //           {
    //             title: '编辑',
    //             name: 'edit',
    //             power: true,
    //             children: []
    //           },
    //           {
    //             title: '二维码',
    //             name: 'qr',
    //             power: true,
    //             children: []
    //           }
    //         ]
    //       },
    //       {
    //         name: 'onsitePlan',
    //         title: '巡检计划',
    //         power: true,
    //         children: [
    //           {
    //             title: '新增',
    //             name: 'add',
    //             power: true,
    //             children: []
    //           },
    //           {
    //             title: '删除',
    //             name: 'delete',
    //             power: true,
    //             children: []
    //           },
    //           {
    //             title: '是否启用',
    //             name: 'use',
    //             power: true,
    //             children: []
    //           }
    //         ]
    //       },
    //       {
    //         name: 'onsiteTask',
    //         title: '巡检任务',
    //         power: true,
    //         children: [
    //           {
    //             title: '导出',
    //             name: 'export',
    //             power: true,
    //             children: []
    //           },
    //           {
    //             title: '督办',
    //             name: 'super',
    //             power: true,
    //             children: []
    //           }
    //         ]
    //       },
    //       {
    //         name: 'superRecord',
    //         title: '督办记录',
    //         power: true,
    //         children: []
    //       }
    //     ]
    //   },
    //   {
    //     name: 'system',
    //     title: '系统管理',
    //     ico: 'iconsystem',
    //     level: true,
    //     power: true,
    //     children: [
    //       {
    //         name: 'version',
    //         title: '版本管理',
    //         power: true,
    //         children: [
    //           {
    //             title: '新增',
    //             name: 'add',
    //             power: true,
    //             children: []
    //           },
    //           {
    //             title: '删除',
    //             name: 'delete',
    //             power: true,
    //             children: []
    //           },
    //           {
    //             title: '编辑',
    //             name: 'edit',
    //             power: true,
    //             children: []
    //           }
    //         ]
    //       },
    //       {
    //         name: 'log',
    //         title: '日志管理',
    //         power: true,
    //         children: []
    //       }
    //     ]
    //   }
    // ]
    // this.$emit('Label', this.getMenuLabel(this.selectArray(this.menus)))
  },
  methods: {
    selectMenu (eq, si) {
      this.$emit('Label', this.getMenuLabel(this.menus[eq], si))
    },
    // 获取导航标签
    getMenuLabel (item, si) {
      let btnGroup = []
      let label = ''
      let seLabel = null
      let path = null
      if (item.level === true && item.power === true) {
        if (si === undefined || si === null) {
          label = item.title
          seLabel = this.selectArray(item.children).title
          btnGroup = this.selectArray(item.children).children
          path = this.selectArray(item.children).name
        } else {
          label = item.title
          seLabel = item.children[si].title
          btnGroup = item.children[si].children
          path = item.children[si].name
        }
      } else if (item.level === false && item.power === true) {
        label = item.title
        seLabel = null
        path = item.name
        btnGroup = item.children
      }
      return { label: label, seLabel: seLabel, path: path, btnGroup: btnGroup }
    },
    selectArray (arr) {
      var brr = []
      arr.forEach((item) => {
        if (item.power) {
          brr.push(item)
        }
      })
      return brr[0]
    }
  }
}
</script>

<style scoped>
.sidebar{
  scrollbar-width: none;
}
.sidebar::-webkit-scrollbar {
  width: 0;
}
.sidebar > ul {
  height: 100%;
  cursor: pointer;
  border-right: 0px;
}
.el-menu-item,.syTitle{
  font-size: 16px;
}
.el-menu-item.is-active{
  background-color: #196DF7 !important;
}
.menuIco{
  font-size: 26px;
  margin-right: 20px;
}
</style>
