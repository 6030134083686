<template>
  <el-container class="setting-container">
    <template v-if="!isShowMain">
      <el-aside class="aside" style="width: 282px">
        <div class="side_back flexAC" v-if="companyid != ''" @click="goBack">
          <span class="iconfont iconico_back"></span>
          <span>设置中心</span>
        </div>
        <div class="side_user flexAC">
          <div><img src="../../assets/img/user_logo.png"/></div>
          <div class="su_right">
            <p class="userName">{{ username }}</p>
            <p class="roleName">{{ rolename }}</p>
            <!-- <div class="flexAC usHand">
              <p class="changePwd" @click="handlePwd">修改密码</p>
              <p style="width:1px;height: 14px;background: #CEDCF8;"></p>
              <p class="handManual">操作手册</p>
            </div> -->
          </div>
        </div>
        <Menu @Label="Label"></Menu>
      </el-aside>
      <el-main class="main_content">
        <div class="menu_label flexCB">
          <div class="label_box flexAC">
            <p>设置中心</p>
            <p style="margin: 0px 5px"> > </p>
            <p>{{label}}</p>
            <p v-if="seLabel !== null" style="margin: 0px 5px"> > </p>
            <p v-if="seLabel !== null">{{seLabel}}</p>
          </div>
          <div class="flexAC">
            <div class="label_out" v-if="companyid != ''" @click="setNotice">
              <div class="hr_item">
                <!-- <span class="iconfont icontongzhishezhi"></span> -->
                <img src="../../assets/img/notice.png"/>
                <span class="hr_itName">通知设置</span>
              </div>
            </div>
            <el-dropdown>
              <div class="hr_item">
                <!-- <span class="iconfont icongengduocaozuo"></span> -->
                <img src="../../assets/img/set.png"/>
                <span class="hr_itName">更多操作</span>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <div @click="handlePwd"><span>修改密码</span> </div>
                </el-dropdown-item>
                <el-dropdown-item>
                  <div @click="handleHlc"><span>帮助中心</span> </div>
                </el-dropdown-item>
                <el-dropdown-item>
                  <div @click="loginOut"><span>退出登录</span> </div>
                </el-dropdown-item>
                <el-dropdown-item>
                  <div @click="manual"><span>操作手册</span> </div>
                </el-dropdown-item>
                <!-- <el-dropdown-item>
                  <div @click="APIChange"><span>公共API</span> </div>
                </el-dropdown-item> -->
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div class="path_contains">
          <div class="setting_mainCont">
              <router-view :roleBtn="roleBtn"></router-view>
          </div>
        </div>
      </el-main>
    </template>

    <template v-if="isShowMain">
      <HelpCenter  @gocenter="gocenter"></HelpCenter>
    </template>

    <!-- 修改密码   -->
    <el-dialog title="修改密码" :visible.sync="showPwd" width="600px" center class="gyDialog" :close-on-click-modal="false" :before-close="pwdCancel">
      <el-form :model="pwdfrom" :rules="formRules" ref="pwdFrom">
        <el-form-item label="旧密码"  prop="password">
          <el-input type="password" v-model="pwdfrom.password"  placeholder="请输入旧密码"></el-input>
        </el-form-item>
        <el-form-item label="新密码"  prop="now_password">
          <el-input type="password" v-model="pwdfrom.now_password"  placeholder="请输入新密码"></el-input>
        </el-form-item>
        <el-form-item label="确认新密码"  prop="now_password2">
          <el-input type="password" v-model="pwdfrom.now_password2"  placeholder="请再次输入新密码"></el-input>
        </el-form-item>
      </el-form>
      <div class="flexCE">
        <div class="settingCancel" @click="pwdCancel">取消</div>
        <div class="settingConfirm" @click="pwdFromConfirm">确定</div>
      </div>
    </el-dialog>

    <!-- 通知设置 -->
    <el-dialog title="通知设置" :visible.sync="showSet" width="910px" center :close-on-click-modal="false" :before-close="setCancel">
      <el-form :model="setfrom" :rules="setformRules" ref="setFrom">
        <div style="display: flex;justify-content: flex-start;align-items: center;margin-bottom: 10px;">
          <div class="udi_k"></div>
          <p class="udi_t">告警弹窗</p>
        </div>
        <el-form-item label="" class="foItem">
          <el-radio-group v-model="setfrom.homepage" style="width:550px;">
            <el-radio label="1">开启</el-radio>
            <el-radio label="2">关闭</el-radio>
          </el-radio-group>
        </el-form-item>
        <div class="ud_left">
          <div style="display: flex;justify-content: flex-start;align-items: center;margin-bottom: 10px;">
            <div class="udi_k"></div>
            <p class="udi_t">告警通知</p>
          </div>
          <div class="flexCB">
            <el-form-item label="火警信息" class="noteItem">
              <el-checkbox  v-model="setfrom.firealarm.rat" true-label="1" false-label="2" >平台通知</el-checkbox>
              <el-checkbox  v-model="setfrom.firealarm.sms" true-label="1" false-label="2" >短信通知</el-checkbox>
              <el-checkbox  v-model="setfrom.firealarm.voe" true-label="1" false-label="2" >语音通知</el-checkbox>
            </el-form-item>
            <el-form-item label="故障" class="noteItem">
                <el-checkbox  v-model="setfrom.faultalarm.rat" true-label="1" false-label="2">平台通知</el-checkbox>
                <el-checkbox  v-model="setfrom.faultalarm.sms" true-label="1" false-label="2">短信通知</el-checkbox>
                <el-checkbox  v-model="setfrom.faultalarm.voe" true-label="1" false-label="2">语音通知</el-checkbox>
              </el-form-item>
           </div>
           <div class="flexCB">
            <el-form-item label="隐患" class="noteItem">
              <el-checkbox  v-model="setfrom.hiddalarm.rat" true-label="1" false-label="2">平台通知</el-checkbox>
              <el-checkbox  v-model="setfrom.hiddalarm.sms" true-label="1" false-label="2">短信通知</el-checkbox>
              <el-checkbox  v-model="setfrom.hiddalarm.voe" true-label="1" false-label="2">语音通知</el-checkbox>
            </el-form-item>
            <el-form-item label="其他" class="noteItem">
              <el-checkbox  v-model="setfrom.otheralarm.rat" true-label="1" false-label="2">平台通知</el-checkbox>
              <el-checkbox  v-model="setfrom.otheralarm.sms" true-label="1" false-label="2">短信通知</el-checkbox>
              <el-checkbox  v-model="setfrom.otheralarm.voe" true-label="1" false-label="2">语音通知</el-checkbox>
            </el-form-item>
           </div>
        </div>
        <div class="ud_left">
         <div style="display: flex;justify-content: flex-start;align-items: center;margin-bottom: 10px;">
            <div class="udi_k"></div>
            <p class="udi_t">其他通知</p>
          </div>
        </div>
         <div class="flexCB">
          <el-form-item label="巡检通知" class="noteItem">
            <el-checkbox  v-model="setfrom.inspealarm.rat" true-label="1" false-label="2">平台通知</el-checkbox>
            <el-checkbox  v-model="setfrom.inspealarm.sms" true-label="1" false-label="2">短信通知</el-checkbox>
            <el-checkbox  v-model="setfrom.inspealarm.voe" true-label="1" false-label="2">语音通知</el-checkbox>
          </el-form-item>
          <el-form-item label="逾期通知" class="noteItem">
            <el-checkbox  v-model="setfrom.withialarm.rat" true-label="1" false-label="2">平台通知</el-checkbox>
            <el-checkbox  v-model="setfrom.withialarm.sms" true-label="1" false-label="2">短信通知</el-checkbox>
            <el-checkbox  v-model="setfrom.withialarm.voe" true-label="1" false-label="2">语音通知</el-checkbox>
          </el-form-item>
         </div>
        <el-form-item label="告警通知" class="noteItem">
          <el-checkbox  v-model="setfrom.declalarm.rat" true-label="1" false-label="2">平台通知</el-checkbox>
          <el-checkbox  v-model="setfrom.declalarm.sms" true-label="1" false-label="2">短信通知</el-checkbox>
          <el-checkbox  v-model="setfrom.declalarm.voe" true-label="1" false-label="2">语音通知</el-checkbox>
        </el-form-item>
      </el-form>
      <div class="flexCE">
        <div class="settingCancel" @click="setCancel">取消</div>
        <div class="settingConfirm" @click="setFromConfirm">确定</div>
      </div>
    </el-dialog>
  </el-container>
</template>

<script>
import Menu from '@/components/setting/menu.vue'
import { userPwdChange } from '@/http/api/user'
import { getnoticeSet, noticeSetup } from '@/http/api/settingSystem'
const SM3 = require('sm-crypto')
import HelpCenter from '@/components/setting/helpCenter.vue'

export default {
  name: 'index',
  components: {
    Menu,
    HelpCenter
  },
  data () {
    const validatePass = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入新密码'))
      } else if (value.toString().length < 6 || value.toString().length > 18) {
        callback(new Error('密码长度为6-18位'))
      } else if (value === this.pwdfrom.password) {
        callback(new Error('新密码不能与原密码相同!'))
      } else {
        callback()
      }
    }
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.pwdfrom.now_password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      label: null,
      seLabel: null,
      path: null,
      username: '',
      companyid: '',
      roleBtn: [],
      showPwd: false,
      pwdfrom: {
        password: '',
        now_password: '',
        now_password2: ''
      },
      formRules: {
        password: [{ required: true, message: '请输入旧密码', trigger: 'blur' }],
        now_password: [{ required: true, validator: validatePass, trigger: 'blur' }],
        now_password2: [{ required: true, validator: validatePass2, trigger: 'blur' }]
      },
      userid: '',
      showSet: false,
      setfrom: {
        homepage: '1',
        firealarm: {
          rat: '0',
          sms: '0',
          voe: '0'
        },
        faultalarm: {
          rat: '0',
          sms: '0',
          voe: '0'
        },
        hiddalarm: {
          rat: '0',
          sms: '0',
          voe: '0'
        },
        otheralarm: {
          rat: '0',
          sms: '0',
          voe: '0'
        },
        inspealarm: {
          rat: '0',
          sms: '0',
          voe: '0'
        },
        withialarm: {
          rat: '0',
          sms: '0',
          voe: '0'
        },
        declalarm: {
          rat: '0',
          sms: '0',
          voe: '0'
        }
      },
      setformRules: {},
      rolename: '',
      isShowMain: false

    }
  },
  mounted () {
    this.$router.push({ path: this.path })
  },
  watch: {},
  created () {
    this.username = JSON.parse(window.localStorage.getItem('loginInfo')).username
    this.rolename = JSON.parse(window.localStorage.getItem('loginInfo')).rolename
    this.companyid = JSON.parse(window.localStorage.getItem('loginInfo')).companyid
    this.userid = JSON.parse(window.localStorage.getItem('loginInfo')).userid
    this.usermsgid = JSON.parse(window.localStorage.getItem('loginInfo')).usermsgid

    var roleper = JSON.parse(window.localStorage.getItem('loginInfo')).roleper
    this.menus = JSON.parse(Base64.decode(roleper))
    let obj = this.getMenuLabel(this.selectArray(this.menus))
    this.label = obj.label
    this.seLabel = obj.seLabel
    this.path = obj.path
    // 按钮权限
    if (obj.btnGroup.length > 0) { this.roleBtn = obj.btnGroup }
    else { this.roleBtn = []}
    // console.log(obj)
  },
  methods: {
    Label (res) {
      this.label = res.label
      this.seLabel = res.seLabel
      this.path = res.path
      // 按钮权限
      if (res.btnGroup.length > 0) {
        this.roleBtn = res.btnGroup
      } else {
        this.roleBtn = []
      }
    },
    // 退出登录
    loginOut () {
      this.$router.push('/Login')
      window.localStorage.setItem('loginInfo', '')
      window.localStorage.setItem('token', '')
      window.localStorage.setItem('tabActive', '')
      window.sessionStorage.setItem('LoginState', '0')
      this.$store.commit('setLoginState', !this.$store.getters.loginState)
    },
    /* 操作手册 */
    manual () {
      window.open(this.BASE_URL + this.$url.PATH.handManual, '_blank')
    },
    // 返回
    goBack () {
      const path = JSON.parse(window.localStorage.getItem('tabActive')).path
      this.$router.push('/' + path)
    },
    handlePwd () {
      this.showPwd = true
    },
    pwdCancel () {
      this.showPwd = false
    },
    // 修改密码提交
    pwdFromConfirm () {
      this.$refs.pwdFrom.validate((valid) => {
        if (!valid) return
        const password = SM3.sm3(this.pwdfrom.password)
        const nowPassword = SM3.sm3(this.pwdfrom.now_password)
        const params = {
          userid: this.userid,
          password: password,
          now_password: nowPassword
        }
        userPwdChange(params).then(res => {
          if (res.status === '1') {
            this.$message.success(res.message)
            setTimeout(() => {
              this.loginOut()
            }, 1000)
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    // 通知设置
    setNotice () {
      this.showSet = true
      getnoticeSet({ usermsgid: this.usermsgid }).then(res => {
        if (res.status === '1') {
          // this.$message.success(res.message)
          this.setfrom.homepage = res.data.homepage
          this.setfrom.firealarm = res.data.firealarm
          this.setfrom.declalarm = res.data.declalarm
          this.setfrom.faultalarm = res.data.faultalarm
          this.setfrom.hiddalarm = res.data.hiddalarm
          this.setfrom.inspealarm = res.data.inspealarm
          this.setfrom.otheralarm = res.data.otheralarm
          this.setfrom.withialarm = res.data.withialarm
        } else {
          this.setfrom = {}
        }
      })
    },
    setFromConfirm () {
      this.$refs.setFrom.validate((valid) => {
        if (!valid) return
        const params = {
          usermsgid: this.usermsgid,
          homepage: this.setfrom.homepage,
          firealarm: this.setfrom.firealarm,
          faultalarm: this.setfrom.faultalarm,
          hiddalarm: this.setfrom.hiddalarm,
          otheralarm: this.setfrom.otheralarm,
          inspealarm: this.setfrom.inspealarm,
          withialarm: this.setfrom.withialarm,
          declalarm: this.setfrom.declalarm
        }
        noticeSetup(params).then(res => {
          if (res.status === '1') {
            this.$message.success(res.message)
            this.setCancel()
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    setCancel () {
      this.showSet = false
    },
    // 公共API
    // APIChange() {
    //   window.open(this.BASE_URL + this.$url.PATH.handAPI, '_blank')
    // }
    // 帮助中心
    handleHlc () {
      this.isShowMain = true
    },
    gocenter () {
      this.isShowMain = false
    },


    // 获取导航标签
    getMenuLabel (item, si) {
      let btnGroup = []
      let label = ''
      let seLabel = null
      let path = null
      if (item.level === true && item.power === true) {
        if (si === undefined || si === null) {
          label = item.title
          seLabel = this.selectArray(item.children).title
          btnGroup = this.selectArray(item.children).children
          path = this.selectArray(item.children).name
        } else {
          label = item.title
          seLabel = item.children[si].title
          btnGroup = item.children[si].children
          path = item.children[si].name
        }
      } else if (item.level === false && item.power === true) {
        label = item.title
        seLabel = null
        path = item.name
        btnGroup = item.children
      }
      return { label: label, seLabel: seLabel, path: path, btnGroup: btnGroup }
    },
    selectArray (arr) {
      var brr = []
      arr.forEach((item) => {
        if (item.power) {
          brr.push(item)
        }
      })
      return brr[0]
    }
  }
}
</script>

<style lang="less" scoped>
.setting-container{
  width: 100%;
  height: 100%;
}
.aside{
  width: 282px;
  background: #196DF7;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: scroll;
  scrollbar-width: none;
}
.aside::-webkit-scrollbar {
  width: 0px;
}
.side_back{
  color: #fff;
  font-size: 20px;
  padding: 0px 20px;
  box-sizing: border-box;
  cursor: pointer;
}
.side_back .iconico_back{
  font-size: 24px;
}
.side_user{
  padding: 45px 20px 35px;
  box-sizing: border-box;
  color: #fff;
}
.su_right{
  margin-left: 16px;
}
.userName{
  font-size: 18px;
}
.roleName {
  margin-top: 5px;
  font-size: 14px;
}
.usHand{
  font-size: 14px;
  color: #CEDCF8;
  margin-top: 6px;
}
.changePwd,.handManual{
  cursor: pointer;
  line-height: 20px;
}
.changePwd{
  margin-right: 10px;
}
.handManual{
  margin-left: 10px;
}
.main_content{
  padding: 0px;
  background: #F0F3F8;
  position: relative;
}
.menu_label{
  width:100%;
  padding: 19px 30px;
  box-sizing: border-box;
  background: #fff;
  font-size: 16px;
  color: #6F7E90;
  position: absolute;
  top: 0;
  z-index: 999;
}
.label_box{
  height: 22px;
  line-height: 22px;
}
.path_contains{
  width: 100%;
  height: 100%;
  padding-top: 60px;
  box-sizing: border-box;
}
.setting_mainCont{
  width: 100%;
  height: 100%;
  position: relative;
  padding: 30px;
  box-sizing: border-box;
}
.label_out{
  cursor: pointer;
  margin-right: 30px;
  font-size: 16px;
  color: #6F7E90;
}
.hr_item {
  display: flex;
  align-items: center;
}
.hr_itName {
  font-size: 16px;
  margin-left: 5px;
  color: #6F7E90;
}
.udi_k {
  width: 4px;
  height: 17px;
  background: #196DF7;
  opacity: 1;
}
.udi_t {
  color: #333333;
  font-size: 18px;
  font-weight: 800;
  margin-left: 10px;
}
</style>
